import React, {useEffect} from "react";
import abimg1 from "../img/about/sobre_img.png";
import { Link, useParams } from "react-router-dom";

const About2 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <>
      <div id="merox-about-area" className="merox-about-area mt-100 mb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 wow fadeInLeft">
              <div className="about-img-area">
                <img src={abimg1} alt="about" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 offset-xl-1 wow fadeInRight">
              <div className="section-title">
                <h4>Quem Somos</h4>
              </div>
              <div className="about-content">
                <p style={{lineHeight:1.4}}>O Complexo Turístico Balneário Praia de Guaratiba pertence ao município de Prado. Uma cidade histórica, que possui praias de água morna, faixas de areia extensas e gastronomia improvável, sendo o refúgio perfeito para quem quer tranquilidade e contato com a natureza. </p> <br/>
                <p style={{lineHeight:1.4}}>O House Maia se localiza dentro do Condomínio Portal das Guaratibas, bastante seguro e completo, ideal para famílias com crianças, principalmente por ser ao lado do playground e entrada da principal trilha ecológica de Guaratiba. Contando também com com 3 barracas de praia, restaurantes, pizzarias, sorveteria, mercearias, padaria, salão de beleza, spa, estética, academia, Crossfit, farmácia, quadras de tênis e beach tênis. </p> <br/>
                <p style={{lineHeight:1.4}}>O House Maia é a realização de um sonho para nós proprietários, que sempre gostamos de viajar e nos hospedar com conforto. Além de sermos apaixonados por experiências gastronômicas. </p> <br/>
                <p style={{lineHeight:1.4}}>Nossa estrutura foi idealizada visando o melhor para os nossos hóspedes, ofertando uma hospedagem de qualidade acompanhadas de incríveis experiências gastronômicas de café da manhã.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About2;
