import React from "react";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";
import { useHistory } from "react-router";
import logo from "../img/logo-1.png";
import Mainslider from "../components/Mainslider";
import redefundomar from "../img/fotos/redefundomar.png";
import quarto from "../img/fotos/quarto.png";
import toalha from "../img/fotos/toalha.png";
import ambiente from "../img/fotos/ambiente.png";
import comida from "../img/fotos/comida.png";
import vistaPorta from "../img/fotos/vistaporta.png";
import Rodape from "../components/rodape.js";
import "../styles/home.css";
import { FaSwimmingPool, FaUtensils, FaSpa, FaHiking, 
        FaConciergeBell, FaWifi, FaCar, 
        FaLanguage, FaPlane, FaUmbrellaBeach, FaClock } from 'react-icons/fa';

const Home = () => {
  const history = useHistory();
  const [ipAddress, setIpAddress] = React.useState("");

  return (
    <>
      <Header />
      <Mainslider />
      <div className="container text-center">
        <div className="mt-4">
          <h1 class="mb-4" style={{fontWeight:500}}>
            Bem-vindo ao House Maia Flats. <span style={{fontWeight:600}}>Seu Refúgio à Beira-Mar!</span>
          </h1>

          <div className="row">
            <div className="col-5 position-relative quadro quadro-1">
              <div className="quadro-fixo">
                <h3>Destino Paradisíaco</h3>
                <p>Descubra o lugar dos seus sonhos!</p>
              </div>
              <img src={vistaPorta} alt="Image" className="h1" />
              <div className="quadro-animado">
                <h3>House Maia Flats </h3>
                <p>
                  Bem-vindo ao nosso refúgio paradisíaco à beira-mar, onde o sol
                  cintilante encontra o oceano sereno. Aqui, cada manhã é uma
                  pintura de cores suaves e o som das ondas embala seus
                  sentidos. Explore trilhas exuberantes, mergulhe em piscinas
                  naturais e entregue-se à culinária local de sabores frescos e
                  exóticos. À noite, sob um manto estrelado, a magia perdura.
                  Este é o lugar onde o tempo desacelera, permitindo que cada
                  momento se torne uma memória eterna. Permita-se ser envolvido
                  pela serenidade e beleza deste paraíso à beira-mar, onde a
                  vida se torna uma celebração constante. 
                  <strong>Venha, a sua jornada rumo ao paraíso começa aqui!</strong>                  
                </p>
              </div>
            </div>
            <div className="col-5 position-relative quadro quadro-1 quadro-2">
              <div className="quadro-fixo">
                <h3>Acomodações de Luxo</h3>
              </div>
              <img src={quarto} alt="Image" className="imagem" />
              <div className="quadro-animado">
                <h3>House Maia Flats </h3>
                <p>
                  Experimente o requinte das nossas acomodações de luxo à beira-mar, 
                  onde o conforto se entrelaça com a serenidade das ondas. 
                  Cada quarto é um santuário de elegância costeira, oferecendo vistas deslumbrantes e 
                  comodidades de alto padrão. 
                  Deixe-se envolver pela experiência única de relaxar em um paraíso à beira da praia, 
                  onde o luxo encontra o horizonte azul.                  
                  <strong>Da elegância do quarto ao espetáculo do oceano, cada detalhe é uma celebração de conforto e beleza à beira-mar</strong>                  
                </p>
              </div>
            </div>

            <div className="position-relative quadro quadro-1 quadro-3">
              <div className="quadro-fixo">
                <h3>Localização Privilegiada</h3>
              </div>
              <img src={ambiente} alt="Image" className="imagem" />
              <div className="quadro-animado">
                <h3>House Maia Flats </h3>
                <p>
                  O House Maia Flats  está estrategicamente situado em uma
                  das áreas mais desejadas da cidade. Com acesso direto à
                  praia, você pode desfrutar de passeios relaxantes à
                  beira-mar ou aproveitar as diversas opções de
                  entretenimento e gastronomia que a região oferece.
                </p>
              </div>
            </div>

            <div className="position-relative quadro quadro-1 quadro-4">
              <div className="quadro-fixo">
                <h3>Experiências Únicas</h3>
              </div>
              <img src={redefundomar} alt="Image" className="imagem" />
              <div className="quadro-animado">
                <h3>House Maia Flats </h3>
                <p>
                  Desfrute da serenidade e beleza incomparável do oceano,
                  enquanto relaxa em nossos flats exclusivos à beira-mar.
                  Aqui, cada amanhecer é uma pintura viva, e o som das ondas
                  é a trilha sonora da sua estadia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{width:"100%", height:"600px", marginTop:"-190px", display:"flex", justifyContent:"center"}} className="position-relative">
        <div className="quadro-fixo-banner">
          <h3>Culinária</h3>
          <p style={{fontSize:'large'}}>
            Em nossa pousada à beira-mar, a experiência culinária é uma celebração de sabores e tradições locais. Nosso talentoso time de chefs combina ingredientes frescos e sazonais para criar pratos que são verdadeiras obras de arte gastronômica. Deixe-se envolver pelos aromas exóticos e sabores autênticos da nossa culinária, enquanto desfruta de vistas deslumbrantes do oceano. Cada refeição é uma jornada sensorial que reflete a paixão e dedicação que temos pela gastronomia. Explore um cardápio diversificado que vai além das fronteiras, oferecendo uma experiência única para o seu paladar. Seja no café da manhã com produtos locais frescos ou em um jantar à luz das estrelas, cada prato é uma experiência que ficará gravada na memória.
          </p>
        </div>
        <img src={comida} alt="Image" className="imagem" style={{width:"70%", height:"100%"}}/>  
      </div>

      <div style={{display:"flex", alignItems:"center", justifyContent:"center", margin:"30px"}}>
        <h1 style={{fontWeight:"600"}}>Nossos Serviços</h1>
      </div>

      <div style={{width: "100%", height:"70vh", display:"flex", marginBottom:"20px"}}>           
        <div style={{width:"50%"}}>
          <img src={vistaPorta} alt="Image" style={{height:"100%", width:"100%"}}/>
        </div>
        <div style={{display:"flex", alignItems:"center", width:"70%", margin:"40px", fontSize:"large", fontFamily:"cursive"}}>
          <ul>
            <li><FaSwimmingPool /> Piscina ao ar livre com vista panorâmica</li>
            <li><FaUtensils /> Restaurante com culinária regional e internacional</li>
            <li><FaSpa /> Spa e tratamentos relaxantes</li>
            <li><FaHiking /> Atividades esportivas: tênis e trilhas</li>
            <li><FaConciergeBell /> Serviço de concierge para passeios e atividades locais</li>
            <li><FaWifi /> Wi-Fi gratuito em todas as áreas</li>
            <li><FaCar /> Estacionamento seguro e gratuito</li>
            <li><FaPlane /> Transfer de/para o aeroporto</li>
            <li><FaClock /> Recepção 24 horas</li>
            <li><FaUmbrellaBeach /> Beach tênis</li>            
          </ul>
        </div>
        <div style={{width:"40%"}}>
          <img src={ambiente} alt="Image" style={{height:"100%", width:"100%"}}/>
        </div>
      </div>

      <BtWhatsapp />
      <Scrolltop />
      <Rodape />
    </>
  );
};

export default Home;
