import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/rodape";
import Scrolltop from "../components/Scrolltop";
import ContactContent from "../components/ContactContent";
import Header from "../components/Header";

import BtWhatsapp from "../components/ButtonWhatsapp";
import Bg from "../img/contact/bg.jpg"

const Contact = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu=""
          name="Contato"
          bg={Bg}
        />
        <ContactContent />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default Contact;
