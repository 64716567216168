import React from 'react';
import BtInstragran from "./ButtonInstagram";

const Rodape = () => {
  return (
    <footer className="bg-dark text-white text-center py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5 style={{color:"white"}} >Contato</h5>
            <p style={{color:"white"}}>Email: <a href='mailto:housemaiaflats@gmail.com'>housemaiaflats@gmail.com</a></p>
            <p style={{color:"white"}}>Telefone: <a href='+5573999034493'>(73) 99903-4493</a></p>
          </div>
          <div className="col-md-4" style={{fontSize:"xx-large"}}>
            <h5 style={{color:"white"}}>Rede Social</h5>
            <BtInstragran />
          </div>
        </div>
        <hr className="my-4" />
        <p style={{color:"white"}}>&copy; 2023 House Maia Flats . Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

export default Rodape;
