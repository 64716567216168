import React from 'react'
import './styles.css'
import Logo from '../../img/loading/logo.svg'

const Loding = ({children}) => {

  return (
    <div className="loading">
      <div className="loading_anim">
      </div>
      <img className="loading_img" src={Logo} alt=""/>
    </div>
  )
};

export default Loding;