import React, { useState, useEffect } from "react";
import details1 from "../img/services/1.jpg";
import details2 from "../img/services/2.jpg";
import details3 from "../img/services/3.jpg";
import details4 from "../img/services/4.jpg";
import details5 from "../img/services/5.jpg";
import details6 from "../img/services/6.jpg";


const Empresarial = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  const dados = [
                  {
                    img1:details1, 
                    titulo1: "Vista diferenciada",
                    img2: details4, 
                    titulo2: "Quartos com ar condicionado"
                  },
                  {
                    img1:details2, 
                    titulo1: "Banheiro privativo",
                    img2: details3, 
                    titulo2: "Cozinha completa"
                  },
                  {
                    img1:details5, 
                    titulo1: "Sala",
                    img2: details6, 
                    titulo2: "Varanda"
                  },
                ]
  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area">
        {dados.map((item)=>{
          return <div className="container">
            <div className="row">
              <div id="menu-left" className="col-xl-4 col-lg-4 d-none d-lg-block">
                
              </div>
              <div className="col-xl-8 col-lg-8 pr-30">
                <div className="merox-blog-details-wraper">
                  <div className="blog-details-content">
                    <h4 style={{color: "#9B0008"}}>{item.titulo1}</h4>
                    <div className="blog-details-img mb-4">
                      <img src={item.img1} alt="img" />
                    </div>

                  </div>
                </div>  
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-8 pr-30">
                <div className="merox-blog-details-wraper">
                  <div className="blog-details-content">
                    <h4 style={{color: "#9B0008"}}>{item.titulo2}</h4>
                    <div className="blog-details-img mb-4">
                      <img src={item.img2} alt="img" />
                    </div>

                  </div>
                </div>  
              </div>
              <div id="menu-left" className="col-xl-4 col-lg-4 d-none d-lg-block">
                
              </div>
            </div>
          </div>

          })
        }
      </div>
    </>
  );
};

export default Empresarial;
