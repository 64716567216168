import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import praia from "../img/fotos/praia.JPG";
import varanda from "../img/fotos/varanda.png";
import faxada from "../img/fotos/faxada.png";
import ambiente from "../img/fotos/ambiente.png";
import quarto from "../img/fotos/quarto.png";

const Mainslider = ({locale}) => {
  const [logoTel, setLogoTel] = useState(locale)
  const [width, setWidth] = useState(1700)
  const [showBox, setShowBox] = useState(true);

  console.log(locale)
  useEffect(()=>{

    setWidth(window.innerWidth)

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  },[])

  function handleScroll() {
    const alturaTotal = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    if (window.pageYOffset / alturaTotal > .35 ) {
      setShowBox(false);
    } else {
      setShowBox(true);
    }
  }

  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
 
  const imagem = [praia, varanda, faxada, ambiente, quarto]

  const videoRef = useRef(null);

  // useEffect(() => {
  //   // Quando o componente montar, inicie a reprodução
  //   videoRef.current.play();
  // }, []);

  return (
    <div className="">
      <div id="merox-slider-area" className="">
        <div className="merox-main-slider">
          <OwlCarousel className="owl-theme owl-carousel" {...options}>
            {
              imagem.map(img=>{
                return <div style={{backgroundImage:`url(${img})`,
                          backgroundSize: 'cover', 
                          backgroundPosition: 'center',
                          height: "80vh"}}>
                  </div>

              })
            }
          </OwlCarousel>
          {/* <div>            
            <video ref={videoRef} autoplay muted loop>
              <source src="https://www.w3schools.com/tags/movie.mp4" type="video/mp4" />
            </video>
          </div> */}
        </div>
      </div>
    </div>
  );

};

export default Mainslider;

