import React from 'react';
import {FaInstagram} from 'react-icons/fa';


const BtInstragran = () => {
  return (
    <div>
      <a href='https://instagram.com/housemaiaflats?igshid=OGQ5ZDc2ODk2ZA==' target="_blank">
        <FaInstagram style={{fontSize:'xxx-large', fontWeight:'800', color: 'white'}}/>
      </a>
    </div>
  )
}


export default BtInstragran;