import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import BtInstragran from "./ButtonInstagram";
import logo from "../img/logo-1-sem-fundo.png"
import {FaInstagram} from 'react-icons/fa';
const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [whidth, setWhidth] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });

    setWhidth(window.innerWidth <= 1200);

  }, []);
  // form class add & delete
  const classadd = () => {
    document.querySelector("#search-btn");
    document.querySelector(".search-form-area").classList.add("active");
  };

  const classdelet = () => {
    document.querySelector("#close-btn");
    document.querySelector(".search-form-area").classList.remove("active");
  };

  return (
    <>
      <header>
        <div 
          className={
            scroll ? "header-area sticky animated slideInDown" : whidth ? "header-area sticky" : "header-area"
          }
        >
          <div className="container">

            <div className="row">
              <div className="col-12">
                <div className="merox-main-menu-area">

                  <nav className="navbar navbar-expand-xl navbar-light main-menu">
                    <Link
                      className="navbar-brand p-0 "
                      to={`${process.env.PUBLIC_URL }`}
                    >
                      <img src={logo} alt="logo" class="logo-main"/>
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fa fa-align-left"></i>
                    </button>

                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto mr-auto">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL }`}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL  + "/acomodacoes"}`}
                          >
                            Acomodações
                          </Link>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Serviços
                          </Link>
                          <div
                            className="dropdown-menu fade-down"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              // to="#"
                              to={`${process.env.PUBLIC_URL  + "/cafemanha"}`}
                            >
                              Café da Manhã
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL  + "/almoco"}`}
                            >
                              Almoço
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL  + "/passeios"}`}
                            >
                              Passeios
                            </Link>
                          </div>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL  + "/about"}`}
                          >
                            Sobre nós
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to={`${process.env.PUBLIC_URL  + "/contact"}`}>
                            {/* <i className="fa fa-phone"></i> */}
                            Fale Conosco
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="https://instagram.com/housemaiaflats?igshid=OGQ5ZDc2ODk2ZA==">
                            {/* <i className="fa fa-phone"></i> */}
                            <FaInstagram style={{fontSize:'xxx-large', fontWeight:'800', color: '#dc3545'}}/>
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default withRouter(Header);
