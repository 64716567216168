import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/rodape";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";

import Bg from "../img/services/bg3.jpg"
import Quartos from "../components/Quartos";

import BtWhatsapp from "../components/ButtonWhatsapp";

const EmpresarialBreadcrumb = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu="Serviços"
          name="Acomodações" 
          bg={Bg}
        />
        <Quartos />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default EmpresarialBreadcrumb;
