import React from "react";
import { Link } from "react-router-dom";


const Breadcrumb = (props) => {
  return (
    <>
      <div id="merox-breadcrumb-area" 
        className="merox-breadcrumb-area"
        style={{
          backgroundImage:  `url('${props.bg}')`
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-content-box">
                <h2>{props.name}</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="">Home</Link>
                  </li>
                  {props.menu === ""
                    ? null
                    : <li className="breadcrumb-item active">{props.menu}</li>
                  }
                  <li className="breadcrumb-item active">{props.name}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
