import {useState, useEffect} from "react";
import HomeDesktop from "./HomeDesktop"
import HomeMobile from "./HomMobile"

const Home = () => {

  const [tamanho, setTamanho] = useState({
    largura: window.innerWidth,
    altura: window.innerHeight
  });

  // Função que será executada quando a janela for redimensionada
  const handleResize = () => {
    setTamanho({
      largura: window.innerWidth,
      altura: window.innerHeight
    });
  };

  useEffect(() => {
    // Adiciona um ouvinte de evento para a janela ser redimensionada
    window.addEventListener('resize', handleResize);

    // Remove o ouvinte de evento quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // O segundo argumento vazio faz com que o efeito seja executado apenas uma vez

  return (
    <>
    {tamanho.largura > 800
      ?<HomeDesktop />
      :<HomeMobile />
    }
      
    </>
  );
};

export default Home;
