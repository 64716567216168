import React from "react";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";
import { useHistory } from "react-router";
import logo from "../img/logo-1.png";
import Mainslider from "../components/Mainslider";
import redefundomar from "../img/fotos/redefundomar.png";
import quarto from "../img/fotos/quarto.png";
import toalha from "../img/fotos/toalha.png";
import ambiente from "../img/fotos/ambiente.png";
import comida from "../img/fotos/comida.png";
import vistaPorta from "../img/fotos/vistaporta.png";
import Rodape from "../components/rodape.js";
import "../styles/home.css";
import { FaSwimmingPool, FaUtensils, FaSpa, FaHiking, 
        FaConciergeBell, FaWifi, FaCar, 
        FaLanguage, FaPlane, FaUmbrellaBeach, FaClock,
        FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const Home = () => {
  const history = useHistory();
  const [ipAddress, setIpAddress] = React.useState("");

  return (
    <>
      <Header />
      <Mainslider />
      <div className="container text-center">
        <div className="mt-4">
          <h1 class="mb-4">
            <p>Bem-vindo ao House Maia Flats</p>  
            Seu Refúgio à Beira-Mar!
          </h1>

          <div className="row">
            <div className="position-relative quadro-mobile">
              <div className="quadro-fixo">
                <h3>Destino Paradisíaco</h3>
                <p>Descubra o lugar dos seus sonhos!</p>
              </div>
              <img src={vistaPorta} alt="Image" className="h1" />
              <div className="quadro-animado-mobile">
                <h3>House Maia Flats</h3>
                <p style={{fontFamily: 'cursive', fontSize:'larger'}}>
                  Bem-vindo ao nosso refúgio paradisíaco à beira-mar, onde o sol
                  cintilante encontra o oceano sereno. Aqui, cada manhã é uma
                  pintura de cores suaves e o som das ondas embala seus
                  sentidos. 
                  <strong>Venha, a sua jornada rumo ao paraíso começa aqui!</strong>                  
                </p>
              </div>
            </div>
            <div className="position-relative quadro-mobile">
              <div className="quadro-fixo">
                <h3>Acomodações de Luxo</h3>
              </div>
              <img src={quarto} alt="Image" className="imagem" />
              <div className="quadro-animado-mobile">
                <h3>House Maia Flats </h3>
                <p style={{fontFamily: 'cursive', fontSize:'larger'}}>
                  Cada quarto é um santuário de elegância costeira, oferecendo vistas deslumbrantes e 
                  comodidades de alto padrão. 
                  <strong>Da elegância do quarto ao espetáculo do oceano, cada detalhe é uma celebração de conforto e beleza à beira-mar</strong>                  
                </p>
              </div>
            </div>

            <div className="position-relative quadro-mobile">
              <div className="quadro-fixo">
                <h3>Localização Privilegiada</h3>
              </div>
              <img src={ambiente} alt="Image" className="imagem" />
              <div className="quadro-animado-mobile">
                <h3>House Maia Flats </h3>
                <p style={{fontFamily: 'cursive', fontSize:'larger'}}>
                  Estamos estrategicamente situado em uma
                  das áreas mais desejadas da cidade. Acesso direto à
                  praia, passeios relaxantes à
                  beira-mar e diversas opções de
                  entretenimento e gastronomia que a região oferece.
                </p>
              </div>
            </div>

            <div className="position-relative quadro-mobile">
              <div className="quadro-fixo">
                <h3>Experiências Únicas</h3>
              </div>
              <img src={redefundomar} alt="Image" className="imagem" />
              <div className="quadro-animado-mobile">
                <h3>House Maia Flats </h3>
                <p style={{fontFamily: 'cursive', fontSize:'large'}}>
                  Desfrute da serenidade e beleza incomparável do oceano,
                  enquanto relaxa em nossos flats exclusivos à beira-mar.
                  Aqui, cada amanhecer é uma pintura viva, e o som das ondas
                  é a trilha sonora da sua estadia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{width:"100%", height: "600px", display:"flex", justifyContent:"center"}} className="position-relative">
        <div className="quadro-fixo-banner" style={{width:"90%"}}>
          <h3>Culinária</h3>
          <p style={{fontSize:'large'}}>
            Em nossa pousada à beira-mar, a experiência culinária é uma celebração de sabores e tradições locais. 
            Nosso talentoso time de chefs combina ingredientes frescos e sazonais que são verdadeiras obras de arte gastronômica.
            Cada refeição é uma jornada sensorial que reflete a paixão e dedicação que temos pela gastronomia. 
            Explore um cardápio diversificado que vai além das fronteiras!
          </p>
        </div>
        <img src={comida} alt="Image" style={{height:"100%", width:"100%"}}/>  
      </div>

      <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"20px"}}>
        <h1>Nossos Serviços</h1>
      </div>

      <div style={{width: "100%", display:"flex"}}>           
        <div style={{display:"flex", 
                    alignItems:"center", 
                    width:"100%", 
                    marginLeft:"10px", 
                    marginTop:"0px", 
                    fontSize:"larger", 
                    fontFamily:"cursive",
                    textAlign:'center'}}>
          <ul style={{listStyle:"none"}}>
            <li><FaSwimmingPool color="red"/> Piscina ao ar livre com vista panorâmica</li>
            <li><FaUtensils color="blue"/> Restaurante com culinária regional e internacional</li>
            <li><FaSpa color="green"/> Spa e tratamentos relaxantes</li>
            <li><FaHiking color="blue"/> Atividades esportivas: tênis e trilhas</li>
            <li><FaConciergeBell color="yellow"/> Serviço de concierge para passeios e atividades locais</li>
            <li><FaWifi color="green"/> Wi-Fi gratuito em todas as áreas</li>
            <li><FaCar /> Estacionamento seguro e gratuito</li>
            <li><FaPlane color="blue"/> Transfer de/para o aeroporto</li>
            <li><FaClock color="green"/> Recepção 24 horas</li>
            <li><FaUmbrellaBeach /> Beach tênis</li>            
          </ul>
        </div>
      </div>

      <BtWhatsapp />
      <Scrolltop />
      <footer className="bg-dark text-center py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{}}>
            <h5 style={{color:"white", fontSize:"xxx-large", fontWeight:"900"}} >Contato</h5>
            <p style={{color:"white"}}><a href='mailto:housemaiaflats@gmail.com' style={{color:"white", fontWeight:"500"}}>housemaiaflats@gmail.com</a></p>
            <p style={{color:"white"}}><a href='+5573999034493' style={{color:"white", fontWeight:"500"}}>(73) 99903-4493</a></p>
          </div>
          <div className="col-md-4" style={{}}>
            <h5 style={{color:"white", fontSize:"xxx-large", fontWeight:"900", marginTop:"20px"}}>Rede Social</h5>
            <div>
              <a style={{color:"white", fontSize:"xx-large"}} href="https://instagram.com/housemaiaflats?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
                <FaInstagram style={{fontSize:"60px", margin:"20px"}}/>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        <p style={{color:"white", fontSize:"x-large"}}>&copy; 2023 House Maia Flats . Todos os direitos reservados.</p>
      </div>
    </footer>    </>
  );
};

export default Home;
