import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import React, {useEffect} from "react";
import About from "./pages/About";
import Error from "./pages/Error";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Quartos from "./pages/Quartos";


function App() {

  return (
    <Router>
        <Route
          exact
          path={"/"}          
          component={Home}
        />
        <Route
          exact
          path={"/contact"}
          component={Contact}
        />
        <Route
          exact
          path={"/about"}
          component={About}
        />
        <Route
          exact
          path={"/acomodacoes"}
          component={Quartos}
        />        
    </Router>
  );
}

export default App;
