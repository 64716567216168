import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Unidades from '../context/Unidades';
import {FaWhatsapp} from 'react-icons/fa';

const BtWhatsapp = () => {
  const [open, setOpen] = useState(false)
  const [is_visible, setIs_Visible] = useState(true)
  const [visibilityMsg, setVisibilityMsg] = useState("hidden")
  const [whatsapp, setWhatsapp] = useState()
  const numberPhone = "+5573999034493"
  
  useEffect(() => {
      var link = "";
      if (window.innerWidth <= 800)
        link = `https://api.whatsapp.com/send/?phone=${numberPhone}`;
      else
      link = `https://web.whatsapp.com/send/?phone=${numberPhone}`;

      setWhatsapp(link);      
  },[])

  return (
    <>
    { is_visible && (
      <div className="whatsapp">
        <div>
          <a href={whatsapp} target="_blank"><FaWhatsapp className="icon mr-2"/></a>
        </div>
      </div>
      /* <div id="msg1" style={{visibility: `${visibilityMsg}`}}><p>Fale conosco via WhatsApp</p></div> */
    )}
    </>
  )
}


export default BtWhatsapp;