import React, { useEffect, useState } from "react";
import Map from "./Map";
import icon1 from "../img/contact/icon-1.png";
import icon2 from "../img/contact/icon-2.png";
import icon3 from "../img/contact/icon-3.png";

import Formulario from "../components/Fomulario"
import {FaRegClock} from "react-icons/fa"
import Unidades from "../context/Unidades";
import { useParams } from "react-router";
import HandleMask from '../context/HandleMask'

const ContactContent = () => {
  return (
    <>
      <div id="merox-contact-area" className="merox-contact-area mt-50 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                <h1>Fale conosco</h1>
                <h3>Contato</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 pr-30">
              <div className="appnox-contact-form-area services-box">
                <Formulario />
                <div className="footer-form">
                  <FaRegClock size={30} />
                  <div>
                    <strong>Horários:</strong>
                    <p>Segunda à sexta de 9:00 às 18:00 / Sábado de 9:00 às 13:00</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 footer-single-wedget-contact" style={{fontSize:"xx-large"}}>
              <ul className="contact-details">
                <li>
                  <i className="fa fa-map-marker"></i>
                  Endereço
                </li>
                <li className="phone-footer">
                  <a href={`tel:+5573999034493`}>
                    <i className="fa fa-phone"></i>
                    (73) 99903-4493
                  </a>
                </li>
                <li className="phone-footer">
                  <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=+5573999034493`} target="_blank">
                    <i className="fa fa-whatsapp"></i>
                    (73) 99903-4493
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ContactContent;
